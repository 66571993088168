<template>
  <div class="box">
    <el-form :model="info" label-position="right" :rules="rules" ref="ruleForm" label-width="100px" class="ruleForm" size="small">
      <el-form-item label="标题" prop="title">
        <el-input v-model="info.title" placeholder="请输入标题"></el-input>
      </el-form-item>
      <el-form-item label="分类" prop="pid">
        <el-select v-model="info.pid" placeholder="请选择">
          <el-option
                  v-for="item in menuList"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="图片" prop="pic">
        <SelectImgs :selectNum="1" :selectData="avatar" :selectFunc="fileChoose"></SelectImgs>
      </el-form-item>
      <el-form-item label="简介" prop="intro">
        <el-input v-model="info.intro" type="textarea" :rows="4" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="内容" prop="content">
        <wang-edit :txt="info.content" :callback="setText"></wang-edit>
      </el-form-item>
      <el-form-item label="推荐商品">
        <el-button type="primary" @click="showModal=true">选择商品</el-button>
        <div class="goods-box">
          <draggable v-model="info.goods" :options="bottomNavOptions">
            <div class="item" v-for="(item,index) in info.goods" :key="index">
              <img :src="item.img" class="img" />
              <div class="name">{{ item.title }}</div>
              <div class="close" title="删除商品" @click="delGoods(index)"><i class="el-icon-error"></i></div>
            </div>
          </draggable>
        </div>
      </el-form-item>
      <el-form-item label="排序">
        <el-input v-model="info.ordering" placeholder="请输入排序"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
        <el-button type="button" @click="reBack()">返回</el-button>
      </el-form-item>
    </el-form>
    <el-dialog
        title="选择商品"
        :visible.sync="showModal"
        :destroy-on-close="true"
        width="900px">
      <goods-list :type="2" :selectFunc="chooseGoods"></goods-list>
    </el-dialog>
  </div>
</template>
<script>

import WangEdit from "../../../components/WangEdit";
import SelectImgs from '../../../components/SelectImgs'
import draggable from 'vuedraggable';
import goodsList from "@/components/Goods/GoodsList";
export default {
  data() {
    return {
      menuList: [],
      showModal: false,
      info: {
        id: 0,
        pid: '',
        pic: '',
        title: '',
        intro: '',
        content: '',
        ordering: ''
      },
      avatar: [],
      source_domain: this.config.SOURCE_DOMAIN,
      rules: {
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' }
        ],
        pid: [
          { required: true, message: '请选择分类', trigger: 'blur' }
        ]
      },
      bottomNavOptions: {
        group: {
          name: 'bottomNav',
          pull: true,
          put: true
        },
        sort: true,
        animation: 100
      }
    };
  },
  components: {
    WangEdit,
    SelectImgs,
    draggable,
    goodsList
  },
  created() {
    this.getMenuList()
    if(this.$route.query.id) {
      this.info.id = this.$route.query.id
      this.getInfo()
    }
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    setText(txt) {
      this.info.content = txt
    },
    chooseGoods(goods) {
      var _this = this
      goods.forEach(function (item) {
        _this.info.goods.push({id: item.id, title: item.title, img: item.img, price: item.price})
      })
      _this.showModal = false
    },
    delGoods(idx) {
      this.info.goods.splice(idx,1)
    },
    getMenuList() {
      var that = this
      this.$api.news.newsMenuTree({},function (res) {
        if(res.errcode == 0) {
          that.menuList = res.data
        }
      })
    },
    getInfo() {
      var that = this
      that.showLoading()
      this.$api.news.newsInfo({id: that.info.id}, function (res) {
        that.hideLoading()
        if(res.errcode == 0) {
          that.info = res.data
          if(that.info.pic) {
            that.avatar = [that.info.pic]
          }
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    submitForm(formName) {
      var that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var param = that.info
          var goodsIds = []
          this.info.goods.forEach(function (item) {
            goodsIds.push(item.id)
          })
          param.goods_ids = goodsIds
          this.$api.news.newsEdit(param,function (res) {
            if(res.errcode == 0) {
              that.success(res.errmsg)
              that.reBack()
            } else {
              that.fail(res.errmsg)
            }
          })
        } else {
          return false;
        }
      });
    },
    fileChoose(item) {
      this.info.pic = item.pic
      this.avatar = [item.pic]
    }
  }
};
</script>
<style scoped>
  .box {
    background-color: #fff;
    padding: 20px;
    border-radius: 6px;
    min-height: 500px;
  }
  .ruleForm {
    width: 800px;
  }
</style>
